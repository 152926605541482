import { Arguments, SWRResponse, mutate as globalMutate } from "swr";
import { FetcherOptions, RemoteDataStatus } from "./types";

export const getRemoteDataStatus = ({
  isValidating,
  error,
}: Partial<SWRResponse>): RemoteDataStatus => {
  if (isValidating) return "validating";
  if (error) return "failure";
  return "success";
};

export const formatPrice = (value: number | undefined) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "EUR",
  }).format(value ?? 0);

export const formatFloat = (value: number | undefined) =>
  (value ?? 0).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const formatIntlDate = (
  date: Date,
  options?: Intl.DateTimeFormatOptions
) => new Intl.DateTimeFormat("de-DE", options).format(date);

export const formatDateTime = (
  date: Date,
  options?: Intl.DateTimeFormatOptions
) =>
  formatIntlDate(date, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...options,
  });

export const parseIntParam = (param: string | null) => {
  if (!param) return;
  const parsed = parseInt(param);
  return !Number.isNaN(parsed) ? parsed : undefined;
};

const isFetcherOptions = (key: Arguments): key is FetcherOptions =>
  (key as FetcherOptions)?.url !== undefined;

export const invalidateCacheByUrl = (url: string) =>
  globalMutate(
    (args) => {
      return isFetcherOptions(args) && args.url === url;
    },
    undefined,
    { revalidate: true }
  );
