import { useState } from "react";
import { EB, parseErrorResponse } from "../http/endbestaendeApi";

const useFormError = <T, U>(
  onSubmit: (body: T) => Promise<U>,
  onClose: () => void
) => {
  const [error, setError] = useState<EB.ErrorResponse | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clearError = () => setError(undefined);

  return {
    handleSubmit: (body: T, onSuccess: (res: U) => void) => {
      setIsSubmitting(true);
      clearError();
      onSubmit(body)
        .then(onSuccess)
        .catch((err) => setError(parseErrorResponse(err)))
        .finally(() => setIsSubmitting(false));
    },
    handleClose: () => {
      clearError();
      onClose();
    },
    isSubmitting,
    error,
    clearError,
  };
};

export default useFormError;
