import { PropsWithChildren, useContext, useEffect, useId } from "react";
import NotificationDispatch, {
  showSuccessNotification,
} from "../context/notificationContext";
import useListFilters from "../hooks/useListFilters";
import useSelectRows from "../hooks/useSelectRows";
import useToggle from "../hooks/useToggle";
import {
  EB,
  deleteProductsData,
  getUseCategoryListUrl,
  getUseProductListUrl,
  patchProductData,
  postProductData,
  useProductList,
} from "../http/endbestaendeApi";
import SvgAdd from "../icons/Add.svg?react";
import SvgCheckbox from "../icons/Checkbox.svg?react";
import SvgCheckboxIndeterminate from "../icons/CheckboxIndeterminate.svg?react";
import SvgCheckboxOutlineBlank from "../icons/CheckboxOutlineBlank.svg?react";
import SvgDelete from "../icons/Delete.svg?react";
import SvgEdit from "../icons/Edit.svg?react";
import {
  formatFloat,
  formatPrice,
  getRemoteDataStatus,
  invalidateCacheByUrl,
} from "../utils";
import AddProductModal from "./AddProductModal";
import DeleteProductModal from "./DeleteProductModal";
import EditProductModal from "./EditProductModal";
import Footer from "./Footer";
import styles from "./Products.module.css";
import Button from "./ui/Button";
import Icon from "./ui/Icon";
import Table from "./ui/Table";
import TableButton from "./ui/TableButton";

interface Props {
  worksheet: EB.WorksheetData;
  categoryId: number;
}

const Products = ({ worksheet, categoryId }: Props) => {
  const filters = useListFilters();
  const query = { ...filters.state, category: categoryId };
  const { data, isValidating, error } = useProductList(worksheet.id, query);
  const multipleSelect = useSelectRows();

  const status = getRemoteDataStatus({ isValidating, error });

  const addModal = useToggle();
  const deleteModal = useToggle();
  const dispatch = useContext(NotificationDispatch);

  const invalidateCache = () => {
    invalidateCacheByUrl(getUseCategoryListUrl(worksheet.id));
    invalidateCacheByUrl(getUseProductListUrl(worksheet.id));
  };

  useEffect(() => {
    if (!data || !data.total) return;
    const lastPage = Math.ceil(data.total / filters.state.limit);
    filters.state.page > lastPage && filters.setPage(lastPage);
  }, [data]);

  return (
    <>
      <Table
        head={
          <tr>
            {!worksheet.locked_at && !!data?.total && (
              <th className={styles.alignLeft}></th>
            )}
            <th className={styles.alignLeft}>Beschreibung</th>
            <th className={styles.alignLeft}>Menge</th>
            <th className={styles.alignLeft}>Einzelpreis ohne MwSt</th>
            <th className={styles.alignLeft}>Gesamt</th>
            {!worksheet.locked_at && <th className={styles.alignLeft}></th>}
          </tr>
        }
        body={data?.products.map((item) => (
          <Row
            key={item.id}
            isSelected={multipleSelect.rows.includes(item.id)}
            onCheckboxClick={() => multipleSelect.toggle(item.id)}
            product={item}
            {...(!worksheet.locked_at && {
              onEditSuccess: () => {
                invalidateCache();
                dispatch(
                  showSuccessNotification("Produkt erfolgreich geändert.")
                );
              },
            })}
          />
        ))}
        filters={filters}
        actions={
          !worksheet.locked_at &&
          !!data?.total &&
          !!multipleSelect.rows.length && (
            <div className={styles.controls}>
              <div className={styles.checkboxColumn}>
                <input
                  className={styles.checkbox}
                  id="checkbox"
                  onClick={() => {
                    const productIds = data.products.map(({ id }) => id);
                    const isEveryVisibleItemSelected = productIds.every((id) =>
                      multipleSelect.rows.includes(id)
                    );
                    const invisibleSelectedIds = multipleSelect.rows.filter(
                      (id) => !productIds.includes(id)
                    );

                    multipleSelect.rows.length === data.total
                      ? multipleSelect.set([])
                      : isEveryVisibleItemSelected
                      ? multipleSelect.set(invisibleSelectedIds)
                      : multipleSelect.addRows(productIds);
                  }}
                />
                <label htmlFor="checkbox" className={styles.checkboxLabel}>
                  <Icon
                    glyph={
                      multipleSelect.rows.length === data.total
                        ? SvgCheckbox
                        : multipleSelect.rows.length
                        ? SvgCheckboxIndeterminate
                        : SvgCheckboxOutlineBlank
                    }
                  />
                </label>
              </div>
              <span className={styles.label}>
                {multipleSelect.rows.length} ausgewählt
              </span>
              <Button
                type="secondary"
                size="small"
                glyph={SvgDelete}
                buttonProps={{
                  onClick: deleteModal.open,
                }}
              >
                Löschen
              </Button>
              <DeleteProductModal
                title="Produkte löschen"
                isOpen={deleteModal.isOpen}
                onClose={deleteModal.close}
                onConfirm={() =>
                  deleteProductsData({ ids: multipleSelect.rows })
                }
                onSuccess={() => {
                  invalidateCache();
                  dispatch(
                    showSuccessNotification(
                      `${multipleSelect.rows.length} ${
                        multipleSelect.rows.length === 1
                          ? "Produkt"
                          : "Produkte"
                      } erfolgreich gelöscht.`
                    )
                  );

                  multipleSelect.set([]);
                  deleteModal.close();
                }}
              >
                <p>
                  Sind Sie sich sicher, dass Sie alle markierten Produkte
                  löschen wollen?
                  <br />
                  Diese Aktion kann nicht rückgängig gemacht werden.
                </p>
              </DeleteProductModal>
            </div>
          )
        }
        total={data?.total ?? 0}
        remoteDataStatus={status}
      />
      {status === "success" && (
        <Footer total={data?.amount}>
          {!worksheet.locked_at && (
            <>
              <Button
                buttonProps={{
                  onClick: addModal.open,
                }}
                glyph={SvgAdd}
              >
                Produkt hinzufügen
              </Button>
            </>
          )}
        </Footer>
      )}
      <AddProductModal
        isOpen={addModal.isOpen}
        onClose={addModal.close}
        onSubmit={(body) =>
          postProductData(worksheet.id, {
            ...body,
            category_id: categoryId,
          })
        }
        onSuccess={() => {
          invalidateCache();
          dispatch(showSuccessNotification("Produkt erfolgreich hinzugefügt."));
        }}
      />
    </>
  );
};

interface RowProps {
  product: EB.ProductData;
  isSelected: boolean | undefined;
  onCheckboxClick: () => void;
  onEditSuccess?: () => void;
}

const Row = ({
  product,
  isSelected,
  onCheckboxClick,
  onEditSuccess,
}: RowProps) => {
  const checkboxId = useId();
  const editModal = useToggle();

  return (
    <tr>
      {onEditSuccess && (
        <td className={styles.checkboxColumn}>
          <input
            className={styles.checkbox}
            id={checkboxId}
            onClick={onCheckboxClick}
          />
          <label htmlFor={checkboxId} className={styles.checkboxLabel}>
            <Icon glyph={isSelected ? SvgCheckbox : SvgCheckboxOutlineBlank} />
          </label>
        </td>
      )}
      <td>{product.description}</td>
      <td>{formatFloat(product.quantity)}</td>
      <td>{formatPrice(product.net_price)}</td>
      <td>{formatPrice((product.quantity ?? 0) * (product.net_price ?? 0))}</td>
      {onEditSuccess && (
        <TableButtonCell>
          <TableButton glyph={SvgEdit} onClick={editModal.open} />
          <EditProductModal
            isOpen={editModal.isOpen}
            onClose={editModal.close}
            onSubmit={(body) =>
              patchProductData(product.id, {
                ...body,
                net_price: body.net_price,
                quantity: body.quantity,
              })
            }
            onSuccess={() => {
              onEditSuccess();
              editModal.close();
            }}
            defaultValues={product}
          />
        </TableButtonCell>
      )}
    </tr>
  );
};

const TableButtonCell = ({ children }: PropsWithChildren) => (
  <td className={styles.tableButtonCell}>
    <div onClick={(e) => e.stopPropagation()}>{children}</div>
  </td>
);

export default Products;
