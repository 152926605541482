import {
  Configuration,
  FrontendApi,
  Identity,
  Session,
} from "@ory/client-fetch";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

export interface User {
  email: string;
  name?: string;
  profilePhoto?: string;
}

type AuthContextType = {
  session?: Session;
  user?: User;
  isNotAsked: boolean;
  logout: () => Promise<void>;
  settingsUrl: string;
};

const AuthContext = createContext<AuthContextType>(undefined!);

const basePath = import.meta.env.VITE_ORY_SDK_URL;
const ory = new FrontendApi(
  new Configuration({
    basePath,
    baseOptions: {
      withCredentials: true,
    },
  })
);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [session, setSession] = useState<Session>();
  const [isNotAsked, setNotAsked] = useState<boolean>(true);

  const logout = async () => {
    const flow = await ory.createBrowserLogoutFlow();
    await ory.updateLogoutFlow(
      {
        token: flow.data.logout_token,
      },
      {
        headers: {
          // Prevents ory from redirecting to a return_to url, which causes a fetch API CORS issue
          Accept: "application/json",
        },
      }
    );
    setSession(undefined);
  };

  useEffect(() => {
    ory
      .toSession()
      .then(({ data }) => setSession(data))
      .finally(() => setNotAsked(false));
  }, []);

  const value = {
    session,
    user: session && getUserFromSession(session),
    isNotAsked,
    logout,
    settingsUrl: `${basePath}/self-service/settings/browser`,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const isEmployee = (email: string) =>
  ["@hgv.it", "@hgj.it"].some((domain) => email.includes(domain));

export const useAuth = () => useContext(AuthContext);

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();

  if (!auth.session && !auth.isNotAsked) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they signin, which is a nicer user experience
    // than dropping them off on the home page.
    window.location.replace(
      `${basePath}/self-service/login/browser?return_to=${window.location.href}`
    );
    return null;
  }

  return children;
};

interface UserIdentity extends Identity {
  traits: {
    email: string;
    name: string;
  };
  metadata_public?: {
    profile_photo?: string;
  };
}

const isUserIdentity = (i: Identity): i is UserIdentity => {
  return [
    "e5cb916994e485d758d041541f12385b37d0f93967d612e0937f60b7b1566012c6ab13379e79a9b4b58fe41f1e675ee80d9e38a2f1660ce67ce981ac3db2a033",
  ].includes(i.schema_id);
};

const getUserFromUserIdentity = (i: UserIdentity): User => {
  return {
    email: i.traits.email,
    name: i.traits.name,
    profilePhoto: i.metadata_public?.profile_photo,
  };
};

interface EmailIdentity extends Identity {
  traits: {
    email: string;
  };
}

const isEmailIdentity = (i: Identity): i is EmailIdentity => {
  return i.schema_id === "preset://email";
};

const getUserFromEmailIdentity = (i: EmailIdentity): User => {
  return {
    email: i.traits.email,
    name: i.traits.email,
  };
};

const getUserFromSession = (session: Session): User => {
  const identity = session.identity;
  if (!identity) {
    throw new Error("no identity found");
  }

  if (isUserIdentity(identity)) return getUserFromUserIdentity(identity);
  else if (isEmailIdentity(identity)) return getUserFromEmailIdentity(identity);

  throw new Error(`unhandled schema '${identity.schema_id}'`);
};
