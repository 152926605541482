import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  EB,
  useCompanies,
  useCompany as useSPCompany,
} from "../http/endbestaendeApi";
import { RemoteDataStatus } from "../types";
import { getRemoteDataStatus, parseIntParam } from "../utils";

type CompanyContextType = {
  company?: EB.Company;
  hasOnlyOneCompany: boolean;
  status: RemoteDataStatus;
  setCompanyId: (companyId?: EB.CompanyId) => void;
};

const CompanyContext = createContext<CompanyContextType>(undefined!);

export const LOCAL_STORAGE_KEY_COMPANY = "selected-company";

export const CompanyProvider = ({ children }: PropsWithChildren) => {
  const localStorageCompanyId = localStorage.getItem(LOCAL_STORAGE_KEY_COMPANY);
  const [companyId, setCompanyId] = useState<number | undefined>(
    parseIntParam(localStorageCompanyId)
  );

  const {
    data,
    isValidating: isCompanyListValidating,
    error: companyListError,
  } = useCompanies({ page: 1, limit: 2 });
  const firstCompany = data?.items[0];
  const companyListStatus = getRemoteDataStatus({
    isValidating: isCompanyListValidating,
    error: companyListError,
  });

  const {
    data: company,
    isValidating: isCompanyValidating,
    error: companyError,
  } = useSPCompany(companyId ?? 0);
  const companyStatus = getRemoteDataStatus({
    isValidating: isCompanyValidating,
    error: companyError,
  });

  const value = {
    company,
    hasOnlyOneCompany: data?.items.length === 1,
    status: mergeStatuses([companyListStatus, companyStatus]),
    setCompanyId: (companyId?: number) => {
      companyId
        ? localStorage.setItem(LOCAL_STORAGE_KEY_COMPANY, companyId.toString())
        : localStorage.removeItem(LOCAL_STORAGE_KEY_COMPANY);
      setCompanyId(companyId);
    },
  };

  useEffect(() => {
    if (companyId) return;
    value.setCompanyId(
      localStorageCompanyId ? parseInt(localStorageCompanyId) : firstCompany?.id
    );
  }, [companyId, firstCompany]);

  return (
    <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
  );
};

const mergeStatuses = (statuses: RemoteDataStatus[]): RemoteDataStatus => {
  if (statuses.includes("failure")) return "failure";
  if (statuses.includes("validating")) return "validating";
  return "success";
};

export const useCompany = () => useContext(CompanyContext);
