import classNames from "classnames";
import { PropsWithChildren, useEffect, useState } from "react";
import styles from "./LoadingSpinner.module.css";

export type Theme = "light" | "dark";

type Props = PropsWithChildren<{
  isLoading?: boolean;
  delayed?: number;
  size?: "small" | "default";
  className?: string;
}>;

const LoadingSpinner = ({
  isLoading = true,
  delayed = 125,
  size = "default",
  className,
  children = null,
}: Props) => {
  const [show, setShow] = useState(isLoading);

  useEffect(() => {
    if (!isLoading) {
      setShow(false);
      return;
    }
    const timeout = setTimeout(() => setShow(true), delayed, [delayed]);
    return () => clearTimeout(timeout);
  }, [isLoading]);

  return (
    <>
      {show ? (
        <div
          className={classNames(styles.loader, className, {
            [styles.small]: size === "small",
          })}
          aria-label="Loading …"
          role="progressbar"
        >
          <div className={styles.track} />
          <div className={styles.fills}>
            <div className={styles.fillMask1}>
              <div className={styles.fillSubMask1}>
                <div className={styles.fill} />
              </div>
            </div>
            <div className={styles.fillMask2}>
              <div className={styles.fillSubMask2}>
                <div className={styles.fill} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default LoadingSpinner;
