import useFormError from "../hooks/useFormError";
import { EB } from "../http/endbestaendeApi";
import Form from "./ui/Form";
import FormField from "./ui/FormField";
import Input from "./ui/Input";
import ModalDialog from "./ui/ModalDialog";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (body: EB.PostProductDataBody) => Promise<EB.ProductData>;
  onSuccess: (productData: EB.ProductData) => void;
}

const AddProductModal = ({ isOpen, onClose, onSubmit, onSuccess }: Props) => {
  const { handleSubmit, handleClose, isSubmitting, error } = useFormError(
    onSubmit,
    onClose
  );

  return (
    <ModalDialog
      title="Produkt hinzufügen"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Form<EB.PostProductDataBody>
        error={error}
        submitText="Speichern & neu"
        isSubmitting={isSubmitting}
        onCancel={handleClose}
        onSubmit={(formData, reset) =>
          handleSubmit(formData, (data) => {
            onSuccess(data);
            reset();
          })
        }
      >
        {({ register, formState: { errors } }) => (
          <>
            <FormField label="Beschreibung" error={errors.description}>
              {({ labelId, isOptional, isInvalid }) => (
                <Input
                  id={labelId}
                  isInvalid={isInvalid}
                  {...register("description", {
                    required: !isOptional,
                  })}
                />
              )}
            </FormField>
            <FormField label="Menge" error={errors.quantity}>
              {({ labelId, isOptional, isInvalid }) => (
                <Input
                  id={labelId}
                  isInvalid={isInvalid}
                  {...register("quantity", {
                    min: 0.01,
                    max: 999999,
                    required: !isOptional,
                    valueAsNumber: true,
                  })}
                  step="0.01"
                  type="number"
                />
              )}
            </FormField>
            <FormField label="Einzelpreis" error={errors.net_price}>
              {({ labelId, isOptional, isInvalid }) => (
                <Input
                  id={labelId}
                  isInvalid={isInvalid}
                  {...register("net_price", {
                    min: 0.01,
                    max: 999999,
                    required: !isOptional,
                    valueAsNumber: true,
                  })}
                  step="0.01"
                  type="number"
                />
              )}
            </FormField>
          </>
        )}
      </Form>
    </ModalDialog>
  );
};

export default AddProductModal;
