import { produce } from "immer";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { useCompany } from "../../context/companyContext";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import {
  patchMemberSetting,
  postWorksheetLock,
  useAdminMembersWorksheets,
} from "../../http/endbestaendeApi";
import SvgDownload from "../../icons/Download.svg?react";
import SvgLock from "../../icons/Lock.svg?react";
import SvgUnlock from "../../icons/Unlock.svg?react";
import { formatDateTime, getRemoteDataStatus } from "../../utils";
import Button, { LinkButton } from "../ui/Button";
import ExpandableRow from "../ui/ExpandableRow";
import Listbox from "../ui/Listbox";
import Search from "../ui/Search";
import Table from "../ui/Table";
import EditEndDateModal from "./EditEndDateModal";
import StatusLight from "./StatusLight";
import styles from "./Worksheets.module.css";

interface Props {
  year: number;
  setYear: (value: number) => void;
  years:
    | {
        years: number[];
      }
    | undefined;
}

const Worksheets = ({ year, setYear, years }: Props) => {
  const dispatch = useContext(NotificationDispatch);
  const { setCompanyId } = useCompany();

  const filters = useListFilters({
    initialState: { q: "", page: 1, limit: 20 },
  });

  const { data, isValidating, error, mutate } = useAdminMembersWorksheets({
    ...filters.state,
    year,
  });

  const remoteStatus = getRemoteDataStatus({ isValidating, error });

  return (
    <>
      <Table
        filters={filters}
        showPaginateBtn={true}
        remoteDataStatus={remoteStatus}
        controls={
          <>
            <Listbox
              label="Jahr:"
              value={year}
              items={years?.years ?? []}
              onChange={setYear}
              displayValue={(i) => `${i}`}
            />
            <Search
              value={filters.state.q ?? ""}
              isValidating={false}
              placeholder="Mitglieder durchsuchen..."
              onChange={filters.setSearchTerm}
            />
          </>
        }
        head={
          <ExpandableRow
            cellType="th"
            cells={[
              <th align="left">Mitglied</th>,
              <th align="left">Status</th>,
              <th align="left">Enddatum</th>,
              <th />,
            ]}
          />
        }
        body={data?.worksheets.map((member, memberKey) => (
          <ExpandableRow
            key={member.id}
            cellType="td"
            cells={[
              <td>
                <div className={styles.name}>{member.name}</div>
              </td>,
              <td>{<StatusLight status={member.status} />}</td>,
              <td>{formatDateTime(new Date(member.end_date))}</td>,
              <td align="right">
                <EditEndDateModal
                  title={member.name}
                  endDate={member.end_date}
                  onSubmit={(value) =>
                    patchMemberSetting(member.id, value)
                      .then(
                        () => (
                          mutate(),
                          dispatch(
                            showSuccessNotification(
                              "Enddatum wurde erfolgreich geändert."
                            )
                          )
                        )
                      )
                      .catch(() =>
                        dispatch(
                          showErrorNotification(
                            "Es ist ein Fehler aufgetreten."
                          )
                        )
                      )
                  }
                />
              </td>,
            ]}
            expandedContent={
              <Table
                head={
                  <tr>
                    <th className={styles.company}>Betriebsstätte</th>
                    <th align="left">Status</th>
                    <th />
                  </tr>
                }
                body={member.companies?.map((c, companyKey) => (
                  <tr key={c.id}>
                    <td>
                      <Link
                        to={c.worksheet ? `/worksheets/${c.worksheet.id}` : "/"}
                        onClick={() => {
                          setCompanyId(c.id);
                        }}
                      >
                        {c.name}
                      </Link>
                    </td>
                    <td>
                      <StatusLight status={c.status} />
                    </td>
                    <td align="right" className={styles.controls}>
                      {c.status !== "completed" && (
                        <Button
                          glyph={SvgLock}
                          type="control"
                          buttonProps={{
                            title: "Sperren",
                            disabled: !c.worksheet,
                            onClick: () => {
                              if (!c.worksheet) return;
                              const optimisticData = {
                                worksheets: produce(
                                  data?.worksheets,
                                  (draft) => {
                                    draft[memberKey]!.companies = produce(
                                      draft[memberKey]!.companies,
                                      (companies) => {
                                        if (!companies) return;
                                        companies[companyKey]!.status =
                                          "completed";
                                      }
                                    );
                                  }
                                ),
                              };

                              mutate(
                                postWorksheetLock(c.id, c.worksheet.id, {
                                  status: "locked",
                                }).then(() => optimisticData),
                                {
                                  optimisticData,
                                  rollbackOnError: true,
                                  revalidate: false,
                                }
                              ).catch(() =>
                                dispatch(
                                  showErrorNotification(
                                    "Es ist ein Fehler aufgetreten."
                                  )
                                )
                              );
                            },
                          }}
                        >
                          Sperren
                        </Button>
                      )}
                      {c.status === "completed" && (
                        <Button
                          glyph={SvgUnlock}
                          type="control"
                          buttonProps={{
                            title: "Entsperren",
                            disabled: !c.worksheet,
                            onClick: () => {
                              if (!c.worksheet) return;
                              const optimisticData = {
                                worksheets: produce(
                                  data?.worksheets,
                                  (draft) => {
                                    draft[memberKey]!.companies = produce(
                                      draft[memberKey]!.companies,
                                      (companies) => {
                                        if (!companies) return;
                                        companies[companyKey]!.status =
                                          "in_progress";
                                      }
                                    );
                                  }
                                ),
                              };

                              mutate(
                                postWorksheetLock(c.id, c.worksheet.id, {
                                  status: "unlocked",
                                }).then(() => optimisticData),
                                {
                                  optimisticData,
                                  rollbackOnError: true,
                                  revalidate: false,
                                }
                              ).catch(() =>
                                dispatch(
                                  showErrorNotification(
                                    "Es ist ein Fehler aufgetreten."
                                  )
                                )
                              );
                            },
                          }}
                        >
                          Entsperren
                        </Button>
                      )}
                      <LinkButton
                        glyph={SvgDownload}
                        type="control"
                        linkProps={{
                          to: `/api/v1/companies/${c.id}/worksheets/${c.worksheet?.id}/export`,
                          target: "_blank",
                        }}
                        buttonProps={{
                          title: "Exportieren",
                          disabled: !c.worksheet,
                        }}
                      >
                        Exportieren
                      </LinkButton>
                    </td>
                  </tr>
                ))}
              />
            }
          />
        ))}
      />
    </>
  );
};

export default Worksheets;
