import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, ReactNode } from "react";
import { DefaultValues, FieldValues, SubmitHandler } from "react-hook-form";
import SvgClose from "../../icons/Close.svg?react";
import Form, { FormChildrenProps } from "./Form";
import Icon from "./Icon";
import styles from "./Modal.module.css";

type Props<TFieldValues extends FieldValues> = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: SubmitHandler<TFieldValues>;
  description?: ReactNode;
  submitText?: string;
  defaultValues?: DefaultValues<TFieldValues> | undefined;
  isScrollable?: boolean;
  children:
    | ReactNode
    | ((useFormProps: FormChildrenProps<TFieldValues>) => ReactNode);
};

const Modal = <TFieldValues extends FieldValues>(
  props: Props<TFieldValues>
) => {
  const {
    title,
    description,
    isOpen,
    submitText = "Speichern",
    children,
    isScrollable = false,
    defaultValues,
    onClose,
    onSubmit,
  } = props;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className={styles.overlay} onClose={onClose}>
        <Dialog.Panel className={styles.dialog}>
          <Dialog.Title className={styles.title}>
            <div className={styles.header}>
              {title}
              <button className={styles.closeButton} onClick={onClose}>
                <Icon className={styles.cancelIcon} glyph={SvgClose} />
              </button>
            </div>
          </Dialog.Title>
          <Dialog.Description>{description}</Dialog.Description>
          <Form<TFieldValues>
            defaultValues={defaultValues}
            submitText={submitText}
            onSubmit={(formData) => onSubmit?.(formData)}
            onCancel={onClose}
          >
            {(formProps) => (
              <div
                className={classNames(styles.form, {
                  [styles.isScrollable]: isScrollable,
                })}
              >
                {typeof children === "function"
                  ? children(formProps)
                  : children}
              </div>
            )}
          </Form>
        </Dialog.Panel>
      </Dialog>
    </Transition>
  );
};

export default Modal;
