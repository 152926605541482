import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { KeyedMutator } from "swr";
import { useWorksheetId } from "../hooks/useRouterParams";
import { EB, useWorksheetData } from "../http/endbestaendeApi";
import { useCompany } from "./companyContext";

export type WorksheetContextType = {
  worksheet?: EB.WorksheetData;
  isValidating: boolean;
  errorCode?: number;
  mutate: KeyedMutator<EB.WorksheetData>;
};

const WorksheetContext = createContext<WorksheetContextType>(undefined!);

export const WorksheetProvider = ({ children }: PropsWithChildren) => {
  const id = useWorksheetId();
  const { company, setCompanyId } = useCompany();

  const { data: worksheet, isValidating, error, mutate } = useWorksheetData(id);

  useMemo(() => {
    if (!worksheet || company?.id === worksheet.company_id) return;
    setCompanyId(worksheet.company_id);
  }, [worksheet]);

  const value = {
    worksheet,
    isValidating,
    errorCode: error ? error.status : undefined,
    mutate,
  };

  return (
    <WorksheetContext.Provider value={value}>
      {children}
    </WorksheetContext.Provider>
  );
};

export const useWorksheet = () => useContext(WorksheetContext);
